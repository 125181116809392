import { Calculation } from 'final-form-calculate'
import { OpcaoSelecionadaRelatorioGerencialEnum, UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'

import {
  calculatePeriodForDay,
  calculatePeriodForMonth,
  calculatePeriodForWeek,
} from '../../form/calculator-relatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialFormModel } from './model-modeloPersonalizadoRelatorioGerencialForm'

export const createModeloPersonalizadoRelatoriosGerenciaisPeriodoFormCalculator = (
  name: MetaRoot<ModeloPersonalizadoRelatorioGerencialFormModel>
): Calculation => ({
  field: [name.opcaoSelecionadaModal.absolutePath(), name.unidadePeriodoModal.absolutePath()],
  updates: {
    [name.periodo.absolutePath()]: (
      _,
      { opcaoSelecionadaModal, unidadePeriodoModal, periodo }: ModeloPersonalizadoRelatorioGerencialFormModel
    ) => {
      const now = new Date()
      if (opcaoSelecionadaModal !== OpcaoSelecionadaRelatorioGerencialEnum.OUTRO) {
        switch (unidadePeriodoModal) {
          case UnidadePeriodoRelatorioGerencialEnum.DIA:
            return calculatePeriodForDay(now, opcaoSelecionadaModal)

          case UnidadePeriodoRelatorioGerencialEnum.SEMANA:
            return calculatePeriodForWeek(now, opcaoSelecionadaModal)

          case UnidadePeriodoRelatorioGerencialEnum.MES:
            return calculatePeriodForMonth(now, opcaoSelecionadaModal)
        }
      } else return periodo
    },
  },
})
