/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme, VFlow } from 'bold-ui'
import { PageLoading } from 'components/loading'
import { TipoModuloEnum } from 'graphql/types.generated'
import { ReactElement, useState } from 'react'

import { KeyMapping } from '../common/keyMapping-relatorioGerencial'
import {
  RelatorioGerencialFiltrosForm,
  RelatorioGerencialFiltrosFormModel,
  RelatorioGerencialFiltrosFormProps,
} from './form/RelatorioGerencialFiltrosForm'
import { ModeloPersonalizadoRelatorioGerencialRootView } from './modelo-personalizado/modal/ModeloPersonalizadoRelatorioGerencialRootView'

export interface RelatorioGerencialTableWrapperProps<T = any>
  extends Omit<RelatorioGerencialFiltrosFormProps<T>, 'setShouldRenderChildren'> {
  loading: boolean
  loadingMessage: string
  isEmpty: boolean
  children: ReactElement
  modulo: TipoModuloEnum
  keyMapping: Map<string, KeyMapping>
}

export function RelatorioGerencialTableWrapper<T = any>(props: RelatorioGerencialTableWrapperProps<T>) {
  const {
    loading,
    isEmpty,
    children,
    hasFiltroCiapCidGrupoCondicoes = false,
    onSubmit,
    setModeloSelecionado,
    modulo,
    keyMapping,
    loadingMessage,
    filtrosLocalStorage,
  } = props

  const [shouldRenderChildren, setShouldRenderChildren] = useState<boolean>(true)

  const theme = useTheme()
  const { container, loadingContainer } = createStyles(theme)

  const handleSubmit = (filtros: RelatorioGerencialFiltrosFormModel) => {
    setShouldRenderChildren(true)
    onSubmit(filtros)
  }

  return (
    <VFlow style={container}>
      <ModeloPersonalizadoRelatorioGerencialRootView modulo={modulo} keyMapping={keyMapping} />
      <RelatorioGerencialFiltrosForm<T>
        hasFiltroCiapCidGrupoCondicoes={hasFiltroCiapCidGrupoCondicoes}
        loading={loading}
        filtrosLocalStorage={filtrosLocalStorage}
        onSubmit={handleSubmit}
        setShouldRenderChildren={setShouldRenderChildren}
        setModeloSelecionado={setModeloSelecionado}
        modulo={modulo}
      />

      {loading ? (
        <div css={loadingContainer}>
          <PageLoading message={loadingMessage} />
        </div>
      ) : isEmpty ? (
        <Text>Nenhum dado para o período</Text>
      ) : (
        shouldRenderChildren && children
      )}
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    color: ${theme.pallete.gray.c20};
  `,
  loadingContainer: css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  `,
})
