import { Button, DataTable, HFlow, Icon, Text, Tooltip } from 'bold-ui'
import { PosicaoCampoRelatorioGerencialEnum } from 'graphql/types.generated'
import React from 'react'
import { KeyMapping } from 'view/relatorio/relatoriosgerenciais/common/keyMapping-relatorioGerencial'

import { ModeloPersonalizadoRelatorioGerencialCampoModel } from './model-modeloPersonalizadoRelatorioGerencialForm'

const MAX_TEXT_LENGTH_ITENS = 110

interface ModeloPersonalizadoRelatorioGerencialCampoDataTableProps {
  campos: ModeloPersonalizadoRelatorioGerencialCampoModel[]
  keyMapping: Map<string, KeyMapping>
  removeItem: (itemToRemove: ModeloPersonalizadoRelatorioGerencialCampoModel) => void
}

export const ModeloPersonalizadoRelatorioGerencialCampoDataTable = (
  props: ModeloPersonalizadoRelatorioGerencialCampoDataTableProps
) => {
  const { campos, keyMapping, removeItem } = props

  const renderButton = (item: ModeloPersonalizadoRelatorioGerencialCampoModel) => (
    <Tooltip text='Excluir'>
      <Button skin='ghost' size='small' onClick={() => removeItem(item)}>
        <Icon icon='trashOutline' />
      </Button>
    </Tooltip>
  )

  const renderCampo = (item: ModeloPersonalizadoRelatorioGerencialCampoModel) => keyMapping.get(item.key)?.keyName

  return (
    <DataTable<ModeloPersonalizadoRelatorioGerencialCampoModel>
      rows={campos}
      sort={['key', 'posicao']}
      columns={[
        {
          name: 'key',
          header: 'Campo',
          sortable: false,
          render: renderCampo,
          style: { width: '25%' },
        },
        {
          name: 'values',
          header: 'Itens',
          sortable: false,
          render: renderItems,
          style: { width: '50%' },
        },
        {
          name: 'posicao',
          header: 'Modo de uso',
          sortable: false,
          render: renderPosicao,
          style: { width: '20%' },
        },
        {
          name: 'button',
          header: '',
          render: renderButton,
          align: 'right',
          style: { width: '10%' },
        },
      ]}
    />
  )
}

const renderPosicao = (item: ModeloPersonalizadoRelatorioGerencialCampoModel) => {
  switch (item.posicao) {
    case PosicaoCampoRelatorioGerencialEnum.FILTRO:
      return (
        <HFlow alignItems='center' hSpacing={0.3}>
          <Icon icon='filterOutline' />
          Filtro
        </HFlow>
      )
    case PosicaoCampoRelatorioGerencialEnum.COLUNA:
      return (
        <HFlow alignItems='center' hSpacing={0.3}>
          <Icon
            icon='hamburguerMenu'
            style={{
              transform: 'rotate(90deg)',
            }}
          />
          Colunas
        </HFlow>
      )
    case PosicaoCampoRelatorioGerencialEnum.LINHA:
      return (
        <HFlow alignItems='center' hSpacing={0.3}>
          <Icon icon='hamburguerMenu' />
          Linhas
        </HFlow>
      )
  }
}

const renderItems = (campo: ModeloPersonalizadoRelatorioGerencialCampoModel) => {
  const allItemsString = campo.items.isEmpty() ? 'Todos os itens' : campo.items.join(', ')
  const substring =
    allItemsString.length > MAX_TEXT_LENGTH_ITENS
      ? `${allItemsString.substring(0, MAX_TEXT_LENGTH_ITENS)}...`
      : allItemsString

  return (
    <Tooltip text={allItemsString}>
      <Text>{substring}</Text>
    </Tooltip>
  )
}
