import { CboSelectModel, ProfissionalSelectModel, UnidadeSaudeSelectModel } from 'components/form'
import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import {
  ClassificacaoPrioridadeCuidadoEnum,
  CompartilhamentoCuidadoPlanoDtoPayload,
  DateRange,
} from 'graphql/types.generated'

import { TipoCompartilhamentoCuidadoPlanoEnum } from '../cuidado-compartilhado/model-cuidadocompartilhado'

export interface CompartilhamentoCuidadoPlanoModel {
  cbo: CboSelectModel
  unidadeSaude?: UnidadeSaudeSelectModel
  lotacaoSolicitante: {
    id: ID
    profissional: {
      id: ID
      nome: string
    }
    cbo: {
      id: ID
      nome: string
    }
  }
  lotacaoExecutante?: {
    id: ID
    profissional: {
      id: ID
      nome: string
    }
  }
  prioridade: ClassificacaoPrioridadeCuidadoEnum
  iniciadoEm: Date
  discussao: string
  problemasECondicoes: {
    ciap?: CiapSelectFieldModel
    cid10?: CidSelectFieldModel
  }
  cidadaoAceitaAtendTic?: SimNaoEnum
  isRegistradoAgora?: boolean
  isEncaminhamento: boolean
  cacheId?: ID
  dbId?: ID
}
export type CompartilhamentoCuidadoPlanoQueryModel = CompartilhamentoCuidadoPlanoDtoPayload['content'][0]

export const filterInitialValues: CompartilhamentoCuidadoTableFilterModel = {
  tiposCompartilhamento: [
    TipoCompartilhamentoCuidadoPlanoEnum.CUIDADO_COMPARTILHADO,
    TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO,
  ],
  cbo: null,
  profissionalSolicitante: null,
  profissionalExecutante: null,
  prioridades: [],
  periodo: null,
  unidadeSaude: null,
}

export interface CompartilhamentoCuidadoTableFilterPopperModel {
  prioridades: ClassificacaoPrioridadeCuidadoEnum[]
  periodo: DateRange
  unidadeSaude: UnidadeSaudeSelectModel
  profissionalSolicitante: ProfissionalSelectModel
  profissionalExecutante: ProfissionalSelectModel
}
export interface CompartilhamentoCuidadoTableFilterModel extends CompartilhamentoCuidadoTableFilterPopperModel {
  tiposCompartilhamento: TipoCompartilhamentoCuidadoPlanoEnum[]
  cbo: CboSelectModel
}
