import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { CompartilhamentoCuidadoPlanoDto } from 'graphql/types.generated'
import { LotacaoContextModel } from 'hooks/atendimento-context/model'
import { emptyArray } from 'util/array'

import {
  CuidadoCompartilhadoPlanoModel,
  TipoCompartilhamentoCuidadoPlanoEnum,
} from '../cuidado-compartilhado/model-cuidadocompartilhado'
import {
  CompartilhamentoCuidadoPlanoModel,
  CompartilhamentoCuidadoPlanoQueryModel,
} from './model-compartilhamentocuidado'

export const joinCompartilhamentosCuidado = (
  compartilhamentosAtendimentoAtual: CuidadoCompartilhadoPlanoModel[],
  compartilhamentosHistorico: CompartilhamentoCuidadoPlanoQueryModel[],
  atendIniciadoEm: Date,
  lotacaoAtual: LotacaoContextModel
): CompartilhamentoCuidadoPlanoModel[] => {
  return [
    ...(compartilhamentosAtendimentoAtual || emptyArray).map((valorAtual) =>
      convertValorAtualToJoined(valorAtual, atendIniciadoEm, lotacaoAtual)
    ),
    ...(compartilhamentosHistorico?.map(convertValorHistoricoToJoined) ?? emptyArray),
  ]
}

const convertValorAtualToJoined = (
  valorAtual: CuidadoCompartilhadoPlanoModel,
  iniciadoEm: Date,
  lotacaoAtual: LotacaoContextModel
): CompartilhamentoCuidadoPlanoModel => ({
  cacheId: valorAtual._id,
  cbo: valorAtual.cbo.cbo,
  unidadeSaude: valorAtual.unidadeSaude,
  lotacaoSolicitante: lotacaoAtual,
  lotacaoExecutante: valorAtual.lotacao,
  prioridade: valorAtual.prioridade,
  iniciadoEm: iniciadoEm,
  discussao: valorAtual.discussao,
  problemasECondicoes: valorAtual.problemasECondicoes,
  cidadaoAceitaAtendTic: valorAtual.cidadaoAceitaAtendTic,
  isRegistradoAgora: true,
  isEncaminhamento: valorAtual.tipo === TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO,
})

const convertValorHistoricoToJoined = (
  valorHistorico: CompartilhamentoCuidadoPlanoDto
): CompartilhamentoCuidadoPlanoModel => ({
  dbId: valorHistorico.id,
  cbo: valorHistorico.cbo,
  unidadeSaude: valorHistorico.unidadeSaude,
  lotacaoSolicitante: valorHistorico.lotacaoSolicitante,
  lotacaoExecutante: valorHistorico.lotacaoExecutanteAtual,
  prioridade: valorHistorico.classificacaoPrioridadeAtual,
  iniciadoEm: valorHistorico.dataInicio,
  discussao: valorHistorico.discussao,
  problemasECondicoes: { ciap: valorHistorico.ciap, cid10: valorHistorico.cid10 },
  cidadaoAceitaAtendTic: valorHistorico.cidadaoAceitaAtendTic ? SimNaoEnum.SIM : SimNaoEnum.NAO,
  isRegistradoAgora: false,
  isEncaminhamento: valorHistorico.isEncaminhamento,
})
