import { SelectField } from 'components/form/final-form'
import { DocumentNode } from 'graphql'
import { TipoModuloEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'

import { AsyncSelectFieldProps, useAsyncQuerySelect } from '../useAsyncQuerySelect'
import { extractItemsUnpaged, hasVariables } from '../util-select'

interface CampoRelatorioGerencialSelectFieldProps extends AsyncSelectFieldProps<string> {
  queryDocument?: DocumentNode
  formatter: (value: string) => string
  tipoModulo: TipoModuloEnum
}

const itemIsEqual = (a: string, b: string) => a === b
const itemToString = (item: string) => item
const variableFunction = (tipoModulo: TipoModuloEnum, hasInput: boolean, hasTipoModulo: boolean) => (input: string) => {
  if (hasInput && hasTipoModulo) {
    return { tipoModulo, input }
  } else if (hasInput) {
    return { input }
  } else if (hasTipoModulo) {
    return { tipoModulo }
  } else return {}
}
export function CampoRelatorioGerencialSelectField(props: CampoRelatorioGerencialSelectFieldProps) {
  const { queryDocument, formatter, tipoModulo, name, ...rest } = props

  const hasTipoModuloVariable =
    hasVariables(queryDocument, 'tipoModulo', 'input') || hasVariables(queryDocument, 'tipoModulo')
  const hasInputVariable = hasVariables(queryDocument, 'tipoModulo', 'input') || hasVariables(queryDocument, 'input')

  const extractItems = (data: Object) => extractItemsUnpaged(queryDocument, data) as string[]

  const { selectProps } = useAsyncQuerySelect({
    query: queryDocument,
    extractItems,
    variables: variableFunction(tipoModulo, hasInputVariable, hasTipoModuloVariable),
    itemToString,
    refetchOnFilterChange: hasInputVariable,
  })

  const { items, ...selectRest } = selectProps
  const formattedItems = useMemo(() => items?.map((item) => (formatter ? formatter(item) : item)), [items, formatter])

  return (
    <SelectField<string>
      items={formattedItems}
      name={name}
      itemToString={itemToString}
      itemIsEqual={itemIsEqual}
      multiple
      placeholder='Todos os itens'
      {...rest}
      {...selectRest}
      clearable
    />
  )
}
