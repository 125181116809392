/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Icon } from 'bold-ui'
import { SelectFieldWithAddButton } from 'components/form/final-form/SelectField/SelectFieldWithAddButton'
import { useModelosByTipoQuery } from 'graphql/hooks.generated'
import { TipoModuloEnum } from 'graphql/types.generated'
import { useField } from 'react-final-form'
import { useHistory, useRouteMatch } from 'react-router'
import { MetaPath } from 'util/metaPath'

import { MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL } from './modal/ModeloPersonalizadorRelatorioGerencialModal'
import { ModeloPersonalizadoModel, modeloPersonalizadoToString } from './model-modeloPersonalizado'

interface ModeloPersonalizadoSelectFieldProps {
  name: MetaPath<ModeloPersonalizadoModel>
  onChange: (modelo: ModeloPersonalizadoModel) => void
  tipoModulo: TipoModuloEnum
}

export function ModeloPersonalizadoSelectField(props: ModeloPersonalizadoSelectFieldProps) {
  const { name, onChange, tipoModulo } = props
  const styles = createStyles()

  const { url } = useRouteMatch()
  const history = useHistory()

  const {
    data: { modelosPersonalizadosByTipoModulo: modelos },
    loading,
  } = useModelosByTipoQuery({ variables: { tipoModulo } })

  const {
    input: { value: modeloSelecionado },
  } = useField<ModeloPersonalizadoModel>(name.absolutePath(), { subscription: { value: true } })

  const handleClickCadastrarModelo = () => {
    history.push(`${url}${MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL}`)
  }

  const handleClickEditarModelo = () => {
    history.push(`${url}${MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL}/${modeloSelecionado?.id}`)
  }

  return (
    <Grid gap={1}>
      <Cell size={4}>
        <SelectFieldWithAddButton<ModeloPersonalizadoModel>
          label='Modelo de relatório com filtros personalizados'
          itemToString={modeloPersonalizadoToString}
          addText={undefined}
          addButtonText='Cadastrar modelo'
          onClickAddButton={handleClickCadastrarModelo}
          name={name}
          onChange={onChange}
          items={modelos}
          loading={loading}
        />
      </Cell>
      <Cell>
        {modeloSelecionado?.id && (
          <Button onClick={handleClickEditarModelo} kind='primary' skin='outline' size='small' style={styles.button}>
            <Icon name='accordionEditIcon' icon='penOutline' style={styles.icon} />
            Editar modelo
          </Button>
        )}
      </Cell>
    </Grid>
  )
}

const createStyles = () => ({
  button: css`
    margin-top: 1.5rem;
  `,
  icon: css`
    margin-right: 0.5rem;
  `,
})
