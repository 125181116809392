/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { formatCiapCid } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { CompartilhamentoCuidadoPlanoModel } from '../model-compartilhamentocuidado'

interface CompartilhamentoCuidadoTablePanelProps {
  row: CompartilhamentoCuidadoPlanoModel
}

export const CompartilhamentoCuidadoTablePanel = (props: CompartilhamentoCuidadoTablePanelProps) => {
  const {
    row: { problemasECondicoes, discussao, lotacaoSolicitante, cidadaoAceitaAtendTic },
  } = props

  return (
    <VFlow vSpacing={0.5} style={styles.panel}>
      <InfoLabel title='Problemas/condições'>
        <Text>{formatCiapCid(problemasECondicoes.ciap, problemasECondicoes.cid10)}</Text>
      </InfoLabel>

      <InfoLabel title='Discussão'>
        <Text dangerouslySetInnerHTML={{ __html: discussao }} style={styles.discussao} />
      </InfoLabel>

      <InfoLabel title='Após devida explicação, o cidadão declara concordar de forma livre e esclarecida que o agendamento da consulta com o especialista poderá ser mediada por tecnologia?'>
        <Text>{cidadaoAceitaAtendTic ? 'Sim' : 'Não'}</Text>
      </InfoLabel>

      <HLabel title='Adicionado por:'>
        <Text>
          {lotacaoSolicitante.profissional.nome} - {lotacaoSolicitante.cbo.nome}
        </Text>
      </HLabel>
    </VFlow>
  )
}

const styles = {
  panel: css`
    padding: 1rem;
    background: ${colors.gray.c90};
  `,
  discussao: css`
    word-break: break-all;
  `,
}
