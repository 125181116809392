import { DateRange } from 'bold-ui'
import { useAlertStore } from 'components/alert'
import useSession from 'components/auth/useSession'
import { AsyncProcessingNameEnum } from 'graphql/types.generated'
import { useAsyncProcessing } from 'hooks/async/useAsyncProcessing'
import { useLocalStorage } from 'hooks/useStorage'
import { useCallback, useEffect } from 'react'

import {
  convertCiapCidFilter,
  convertRelatorioGerencialFormModelToStorageModel,
  convertRelatorioGerencialStorageModelToFormModel,
} from '../components/form/converter-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../components/form/RelatorioGerencialFiltrosForm'
import { RelatorioGerencialFiltrosStorageModel } from './model-relatorioGerencial'

interface UseAsyncRelatorioGerencialProps {
  asyncProcessingName: AsyncProcessingNameEnum
  filterStorageKey: string
  isSearchingMeta: boolean
  hasMetaState: boolean
  onGetMeta: (filtros: RelatorioGerencialFiltrosFormModel) => void
  meta: (filterState: DateRange, periodUnit: string, filtroCiapCid?: string[]) => void
  setIsSearchingMeta: (isSearchingMeta: boolean) => void
}

interface UseAsyncRelatorioGerencialResult {
  filtros: RelatorioGerencialFiltrosFormModel
  isAsyncProcessingRunning: boolean
  clearFiltros: () => void
  handleSubmit: (filtros: RelatorioGerencialFiltrosFormModel) => void
  setFiltros: (filtros: RelatorioGerencialFiltrosStorageModel) => void
}

export function useAsyncRelatorioGerencial(props: UseAsyncRelatorioGerencialProps): UseAsyncRelatorioGerencialResult {
  const {
    asyncProcessingName,
    filterStorageKey,
    isSearchingMeta,
    hasMetaState,
    onGetMeta,
    meta,
    setIsSearchingMeta,
  } = props

  const { data } = useSession()
  const acessoId = data?.acesso?.id

  const [getFiltros, setFiltros, clearFiltros] = useLocalStorage<RelatorioGerencialFiltrosStorageModel>(
    `${filterStorageKey}/${acessoId}`
  )

  const { alert } = useAlertStore()

  const filtrosAsFormModel = convertRelatorioGerencialStorageModelToFormModel(getFiltros())

  const getMeta = useCallback(
    ({ ciapsCids, gruposCondicoesPrioritarios, periodo, unidade }: RelatorioGerencialFiltrosFormModel) => {
      const ciapCidFilterConverted = convertCiapCidFilter(ciapsCids, gruposCondicoesPrioritarios)
      meta(periodo, unidade, ciapCidFilterConverted)
    },
    [meta]
  )

  const onProcessingFinished = useCallback(
    (errorMessage?: string) => {
      if (errorMessage) {
        setIsSearchingMeta(false)
        alert('danger', `Não foi possível buscar os dados do relatório porque ocorreu o seguinte erro: ${errorMessage}`)
      } else {
        getMeta(filtrosAsFormModel)
      }
    },
    [alert, filtrosAsFormModel, getMeta, setIsSearchingMeta]
  )

  const { isStarted, isFinished, hasError, removerProcessoAssincrono } = useAsyncProcessing({
    asyncProcessingName,
    onProcessingFinished,
  })

  // Buscando os dados quando o usuario voltar para a pagina do modulo soh depois de terminar o processamento
  useEffect(() => {
    const isSuccessfullyFinished = isFinished && !hasError
    if (isStarted && isSuccessfullyFinished && filtrosAsFormModel && !isSearchingMeta && !hasMetaState) {
      removerProcessoAssincrono()
      getMeta(filtrosAsFormModel)
      onGetMeta(filtrosAsFormModel)
    }
  }, [
    filtrosAsFormModel,
    getMeta,
    hasError,
    hasMetaState,
    isFinished,
    isSearchingMeta,
    isStarted,
    onGetMeta,
    removerProcessoAssincrono,
  ])

  const handleSubmit = useCallback(
    (filtros: RelatorioGerencialFiltrosFormModel) => {
      onGetMeta(filtros)
      setFiltros(convertRelatorioGerencialFormModelToStorageModel(filtros))
      getMeta(filtros)
    },
    [getMeta, onGetMeta, setFiltros]
  )

  return {
    filtros: filtrosAsFormModel,
    isAsyncProcessingRunning: isStarted && !isFinished,
    clearFiltros,
    handleSubmit,
    setFiltros,
  }
}
