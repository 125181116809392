import { CboCuidadoCompartilhadoSelectModel } from 'components/form/field/select/CboCuidadoCompartilhadoSelectField/CboCuidadoCompartilhadoSelectField'
import { Lotacao, UnidadeSaude } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { MetaRoot } from 'util/metaPath'

import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'

export const cuidadoCompartilhadoFormCalculator = (meta: MetaRoot<CuidadoCompartilhadoPlanoModel>, flag: boolean) => {
  return flag
    ? [
        {
          field: meta.cbo.absolutePath(),
          updates: {
            [meta.unidadeSaude.absolutePath()]: (
              value: CboCuidadoCompartilhadoSelectModel,
              _,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              return isUndefinedOrNull(value) || value !== prevValues.cbo ? null : prevValues.unidadeSaude
            },
            [meta.lotacao.absolutePath()]: (
              value: CboCuidadoCompartilhadoSelectModel,
              _,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              return isUndefinedOrNull(value) || value !== prevValues.cbo ? null : prevValues.lotacao
            },
            [meta.cidadaoAceitaAtendTic.absolutePath()]: (
              value: CboCuidadoCompartilhadoSelectModel,
              _,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              return isUndefinedOrNull(value) || !value.isVinculado ? null : prevValues.cidadaoAceitaAtendTic
            },
          },
        },
        {
          field: meta.unidadeSaude.absolutePath(),
          updates: {
            [meta.lotacao.absolutePath()]: (
              value: UnidadeSaude,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              return isUndefinedOrNull(value) ||
                (!isEmpty(prevValues) && value?.id !== prevValues.lotacao?.unidadeSaude?.id)
                ? null
                : allValues.lotacao
            },
          },
        },
      ]
    : [
        {
          field: meta.lotacao.absolutePath(),
          updates: {
            [meta.cbo.absolutePath()]: (value: Lotacao, _, prevValues: CuidadoCompartilhadoPlanoModel) => {
              if (!isUndefinedOrNull(value)) return { cbo: value.cbo, isVinculado: true }
              return prevValues.cbo
            },
          },
        },
        {
          field: meta.cbo.absolutePath(),
          updates: {
            [meta.lotacao.absolutePath()]: (
              value: CboCuidadoCompartilhadoSelectModel,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              return isUndefinedOrNull(value) ||
                (!isEmpty(prevValues) && value?.cbo?.id !== prevValues.lotacao?.cbo?.id)
                ? null
                : allValues.lotacao
            },
          },
        },
      ]
}
