/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Tooltip } from 'bold-ui'
import { Box } from 'components/Box'
import { Form, FormRenderProps, ProfissionalSelectField, UnidadeSaudeSelectField } from 'components/form'
import { CheckboxGroupField } from 'components/form/field/CheckboxGroupField'
import DateRangeField from 'components/form/field/DateRangeField'
import { ClassificacaoPrioridadeCuidadoEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'
import { filterCheckboxLabelRender } from 'view/atendimentos/list/filter/ListaAtendimentoFilterPopperForm'

import { cuidadoCompartilhadoClassificacaoPrioridadeRecord } from '../../cuidado-compartilhado/model-cuidadocompartilhado'
import {
  CompartilhamentoCuidadoTableFilterModel,
  CompartilhamentoCuidadoTableFilterPopperModel,
} from '../model-compartilhamentocuidado'

interface CompartilhamentoCuidadoTableFilterPopperProps {
  initialValues: CompartilhamentoCuidadoTableFilterModel
  onSubmit: (values: CompartilhamentoCuidadoTableFilterPopperModel) => void
  onClose: () => void
}

const path = metaPath<CompartilhamentoCuidadoTableFilterPopperModel>()

export const CompartilhamentoCuidadoTableFilterPopper = (props: CompartilhamentoCuidadoTableFilterPopperProps) => {
  const { initialValues, onSubmit, onClose } = props

  const styles = createStyles()

  const handleFilterDefault = () => {
    onSubmit({
      prioridades: [],
      periodo: null,
      unidadeSaude: null,
      profissionalSolicitante: null,
      profissionalExecutante: null,
    })
  }

  const isFilterDefault =
    initialValues.prioridades.length === 0 &&
    !initialValues.periodo &&
    !initialValues.unidadeSaude &&
    !initialValues.profissionalSolicitante &&
    !initialValues.profissionalExecutante

  const renderForm = (formProps: FormRenderProps) => (
    <Box style={styles.box}>
      <Grid gap={1}>
        <Cell size={12}>
          <CheckboxGroupField
            name={path.prioridades}
            label='Prioridade'
            options={[
              {
                label: filterCheckboxLabelRender(
                  cuidadoCompartilhadoClassificacaoPrioridadeRecord.BAIXA.label,
                  styles.checkbox[ClassificacaoPrioridadeCuidadoEnum.BAIXA]
                ),
                value: ClassificacaoPrioridadeCuidadoEnum.BAIXA,
              },
              {
                label: filterCheckboxLabelRender(
                  cuidadoCompartilhadoClassificacaoPrioridadeRecord.MEDIA.label,
                  styles.checkbox[ClassificacaoPrioridadeCuidadoEnum.MEDIA]
                ),
                value: ClassificacaoPrioridadeCuidadoEnum.MEDIA,
              },
              {
                label: filterCheckboxLabelRender(
                  cuidadoCompartilhadoClassificacaoPrioridadeRecord.ALTA.label,
                  styles.checkbox[ClassificacaoPrioridadeCuidadoEnum.ALTA]
                ),
                value: ClassificacaoPrioridadeCuidadoEnum.ALTA,
              },
              {
                label: filterCheckboxLabelRender(
                  cuidadoCompartilhadoClassificacaoPrioridadeRecord.MUITO_ALTA.label,
                  styles.checkbox[ClassificacaoPrioridadeCuidadoEnum.MUITO_ALTA]
                ),
                value: ClassificacaoPrioridadeCuidadoEnum.MUITO_ALTA,
              },
            ]}
          />
        </Cell>
        <Cell size={6}>
          <DateRangeField name={path.periodo} label='Período de compartilhamento' />
        </Cell>
        <Cell size={6}>
          <UnidadeSaudeSelectField name={path.unidadeSaude} label='Unidade de saúde' />
        </Cell>
        <Cell size={6}>
          <ProfissionalSelectField name={path.profissionalSolicitante} label='Profissional solicitante' />
        </Cell>
        <Cell size={6}>
          <ProfissionalSelectField name={path.profissionalExecutante} label='Profissional executante' />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' size='small' onClick={onClose}>
              Fechar
            </Button>
            <Tooltip text={isFilterDefault && 'Redefinir para filtro padrão'}>
              <Button kind='normal' size='small' onClick={handleFilterDefault} disabled={isFilterDefault}>
                <Icon icon='redo' style={styles.icon} />
                Voltar para o padrão
              </Button>
            </Tooltip>
            <Button
              kind='primary'
              size='small'
              onClick={() => {
                formProps.handleSubmit()
                onClose()
              }}
            >
              Filtrar
            </Button>
          </HFlow>
        </Cell>
      </Grid>
    </Box>
  )
  return <Form render={renderForm} onSubmit={onSubmit} initialValues={initialValues} />
}

const createStyles = () => ({
  box: css`
    width: 42rem;
  `,
  icon: css`
    margin-right: 0.5rem;
  `,
  checkbox: {
    [ClassificacaoPrioridadeCuidadoEnum.BAIXA]: css`
      background-color: ${cuidadoCompartilhadoClassificacaoPrioridadeRecord.BAIXA.mainColor};
    `,
    [ClassificacaoPrioridadeCuidadoEnum.MEDIA]: css`
      background-color: ${cuidadoCompartilhadoClassificacaoPrioridadeRecord.MEDIA.mainColor};
    `,
    [ClassificacaoPrioridadeCuidadoEnum.ALTA]: css`
      background-color: ${cuidadoCompartilhadoClassificacaoPrioridadeRecord.ALTA.mainColor};
    `,
    [ClassificacaoPrioridadeCuidadoEnum.MUITO_ALTA]: css`
      background-color: ${cuidadoCompartilhadoClassificacaoPrioridadeRecord.MUITO_ALTA.mainColor};
    `,
  },
})
