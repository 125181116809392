import { DateRange } from 'bold-ui'
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns'
import { Calculation } from 'final-form-calculate'
import { OpcaoSelecionadaRelatorioGerencialEnum, UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'

import {
  relatorioGerencialDiaOpcoesRecord,
  relatorioGerencialMesOpcoesRecord,
  relatorioGerencialSemanaOpcoesRecord,
} from '../../common/model-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from './RelatorioGerencialFiltrosForm'

export const createRelatoriosGerenciaisPeriodoFormCalculation = (
  name: MetaRoot<RelatorioGerencialFiltrosFormModel>
): Calculation => ({
  field: [name.opcaoSelecionada.absolutePath(), name.unidade.absolutePath()],
  updates: {
    [name.periodo.absolutePath()]: (_, { opcaoSelecionada, unidade, periodo }: RelatorioGerencialFiltrosFormModel) => {
      return calculatePeriodoRelatorioGerencial(unidade, opcaoSelecionada, periodo)
    },
  },
})

type RelatoriosGerenciaisOpcoesCalculaveis =
  | OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA
  | OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA
  | OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA

export const calculatePeriodoRelatorioGerencial = (
  unidade: UnidadePeriodoRelatorioGerencialEnum,
  opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum,
  periodo?: DateRange
) => {
  if (opcaoSelecionada !== OpcaoSelecionadaRelatorioGerencialEnum.OUTRO) {
    const now = new Date()
    switch (unidade) {
      case UnidadePeriodoRelatorioGerencialEnum.DIA:
        return calculatePeriodForDay(now, opcaoSelecionada)

      case UnidadePeriodoRelatorioGerencialEnum.SEMANA:
        return calculatePeriodForWeek(now, opcaoSelecionada)

      case UnidadePeriodoRelatorioGerencialEnum.MES:
        return calculatePeriodForMonth(now, opcaoSelecionada)
    }
  } else return periodo
}

export const calculatePeriodForMonth = (
  now: Date,
  opcaoSelecionada: RelatoriosGerenciaisOpcoesCalculaveis
): DateRange => {
  const lastMonth = subMonths(now, 1)
  const month = subMonths(now, relatorioGerencialMesOpcoesRecord[opcaoSelecionada].quantityToSubtract)

  return {
    startDate: startOfMonth(month),
    endDate: endOfMonth(lastMonth),
  }
}

export const calculatePeriodForWeek = (
  now: Date,
  opcaoSelecionada: RelatoriosGerenciaisOpcoesCalculaveis
): DateRange => {
  const lastWeek = subWeeks(now, 1)
  const week = subWeeks(now, relatorioGerencialSemanaOpcoesRecord[opcaoSelecionada].quantityToSubtract)

  return {
    startDate: startOfWeek(week),
    endDate: endOfWeek(lastWeek),
  }
}

export const calculatePeriodForDay = (
  now: Date,
  opcaoSelecionada: RelatoriosGerenciaisOpcoesCalculaveis
): DateRange => {
  const day = subDays(now, relatorioGerencialDiaOpcoesRecord[opcaoSelecionada].quantityToSubtract)

  return {
    startDate: startOfDay(day),
    endDate: endOfDay(now),
  }
}
