import { VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useListaCuidadoCompartilhadoQuery } from 'graphql/hooks.generated'
import { Action, CuidadoCompartilhadoQueryFilterEnum, CuidadosCompartilhadoQueryInput } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { debounce, isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'

import {
  CuidadoCompartilhadoItemActions,
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
  ListaCuidadoCompartilhadoInlineFilterModel,
} from '../model-cuidadoCompartilhado'
import { ListaCuidadoCompartilhadoFilter } from './lista-cuidado-compartilhado-filter/ListaCuidadoCompartilhadoFilter'
import ListaCuidadoCompartilhadoTable from './lista-cuidado-compartilhado-table/ListaCuidadoCompartilhadoTable'

export interface CuidadoCompartilhadoAtmosphereResponse {
  idProfissionalSolicitante: string
  idEquipeSolicitante: string
  idProfissionalExecutante: string
  idEquipeExecutante: string
}

interface ListaCuidadoCompartilhadoViewProps {
  defaultSort: CuidadoCompartilhadoSortEnum
  filterSomenteOsMeusLabel?: string
  itemsToSort: CuidadoCompartilhadoSortEnum[]
  role: CuidadoCompartilhadoQueryFilterEnum
  filterToQueryInput(value: ListaCuidadoCompartilhadoFilterModel): CuidadosCompartilhadoQueryInput
  resolveDiscutirAction?(actions: CuidadoCompartilhadoItemActions): Action
}

export const ListaCuidadoCompartilhadoView = (props: ListaCuidadoCompartilhadoViewProps) => {
  const {
    defaultSort,
    itemsToSort,
    filterSomenteOsMeusLabel,
    role,
    filterToQueryInput,
    resolveDiscutirAction = () => null,
  } = props
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()

  const [isFilterDefault, setIsFilterDefault] = useState(true)
  const [filter, setFilter] = useState<ListaCuidadoCompartilhadoFilterModel>({
    somenteOsMeus: true,
    pageParams: {
      size: 5,
      page: 0,
      sort: defaultSort,
    },
  })

  const { data, loading, refetch } = useListaCuidadoCompartilhadoQuery({
    variables: { input: filterToQueryInput(filter) },
  })

  const onAtmosphereMessage = (response: CuidadoCompartilhadoAtmosphereResponse) => {
    const profissinalSolicitanteMatches = response.idProfissionalSolicitante === profissional.id
    const profissinalExecutanteMatches = response.idProfissionalExecutante === profissional.id
    const equipeFieldsNotNull = !!acesso.equipe && (!!response.idEquipeSolicitante || !!response.idEquipeExecutante)
    const equipeSolicitanteMatches = equipeFieldsNotNull && response.idEquipeSolicitante === acesso.equipe?.id
    const equipeExecutanteMatches = equipeFieldsNotNull && response.idEquipeExecutante === acesso.equipe?.id

    if (
      profissinalSolicitanteMatches ||
      profissinalExecutanteMatches ||
      equipeSolicitanteMatches ||
      equipeExecutanteMatches
    ) {
      debounce(refetch, 350)()
    }
  }

  useAtmosphere<CuidadoCompartilhadoAtmosphereResponse>({
    topic: `cuidadocompartilhado`,
    onMessage: onAtmosphereMessage,
  })

  const onFilterChange = (value: ListaCuidadoCompartilhadoInlineFilterModel) => {
    setFilter((oldValue: ListaCuidadoCompartilhadoFilterModel) => ({
      ...oldValue,
      ...value,
    }))
  }

  useEffect(() => {
    setIsFilterDefault(isFilterEqualFilterDefault(filter, FILTER_DEFAULT))
  }, [filter])

  return (
    <VFlow vSpacing={0.5}>
      <ListaCuidadoCompartilhadoFilter
        value={filter}
        itemsToSort={itemsToSort}
        filterSomenteOsMeusLabel={filterSomenteOsMeusLabel}
        isFilterDefault={isFilterDefault}
        role={role}
        onChange={onFilterChange}
        onClear={() => onFilterChange(FILTER_DEFAULT)}
      />
      <ListaCuidadoCompartilhadoTable
        loading={loading}
        setFilter={setFilter}
        isGerente={role === CuidadoCompartilhadoQueryFilterEnum.GERENTE}
        queryPayload={data?.cuidadosCompartilhado}
        resolveDiscutirAction={resolveDiscutirAction}
      />
    </VFlow>
  )
}

const FILTER_DEFAULT: ListaCuidadoCompartilhadoFilterModel = {
  status: [],
  periodo: null,
  profissionalExecutante: null,
  profissionalSolicitante: null,
  unidadeSaude: null,
  equipe: [],
}

const isFilterEqualFilterDefault = (
  filter: ListaCuidadoCompartilhadoFilterModel,
  filterDefault: ListaCuidadoCompartilhadoFilterModel
): boolean => {
  const { pageParams, query, somenteOsMeus, ...rest } = filter

  return isEqual(
    {
      status: (rest.status ?? []).sort(),
      periodo: rest.periodo ?? null,
      profissionalExecutante: rest.profissionalExecutante ?? null,
      profissionalSolicitante: rest.profissionalSolicitante ?? null,
      unidadeSaude: rest.unidadeSaude ?? null,
      equipe: (rest.equipe ?? []).sort(),
    },
    filterDefault
  )
}
