import { Label, LabelMap } from 'util/fields-labels/model'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { AntecedentePuericulturaModel } from '../antecedentes/model'
import { FinalizacaoAtendimentoFormModel } from '../finalizacao'
import { PrenatalPartoNascimentoModel } from '../objetivo/puericultura/model'

const PRENATAL_PARTO_NASCIMENTO_FORM_LABELS: Label<PrenatalPartoNascimentoModel & AntecedentePuericulturaModel> = {
  label: 'Pré-natal, parto e nascimento',
  fields: {
    apgar: {
      label: 'Apgar',
      fields: {
        apgarUm: { label: '1º minuto' },
        apgarCinco: { label: '5º minuto' },
        apgarDez: { label: '10º minuto' },
      },
    },
    antropometriaAoNascer: {
      label: 'Antropometria ao nascer',
      fields: {
        comprimento: { label: 'Comprimento (cm)' },
        perimetroCefalico: { label: 'Perímetro cefálico (cm)' },
        peso: { label: 'Peso (kg)' },
      },
    },
    idadeGestacional: { label: 'Idade gestacional' },
    tipoGravidez: { label: 'Tipo de gravidez' },
    tipoParto: { label: 'Tipo de parto' },
    dataAtualizacao: null,
  },
}
const AGENDAR_CONSULTAS_FORM_LABELS = {
  data: { label: 'Data do agendamento' },
  horario: { label: 'Horário do agendamento' },
  lotacao: { label: 'Profissional' },
  isForaUbs: { label: 'Fora da UBS' },
  localAtendimento: { label: 'Local de atendimento' },
  observacoes: { label: 'Observações' },
}

const FINALIZACAO_ATENDIMENTO_FORM_LABELS: Label<FinalizacaoAtendimentoFormModel> = {
  label: 'Finalização do atendimento',
  fields: {
    conduta: { label: 'Conduta' },
    condutasOdonto: { label: 'Conduta' },
    tipoAtendimento: { label: 'Tipo de atendimento' },
    tipoConsulta: { label: 'Tipo de consulta' },
    agendamentoConsultas: {
      label: 'Agendar consulta',
      fields: {
        avulsa: {
          label: 'Consulta avulsa',
          fields: AGENDAR_CONSULTAS_FORM_LABELS,
        },
        proximasConsultas: {
          label: 'Próximas consultas',
          fields: {
            proximaConsultaOdontologica: {
              label: 'Próxima consulta odontológica',
              fields: AGENDAR_CONSULTAS_FORM_LABELS,
            },
            proximaConsultaPreNatal: {
              label: 'Próxima consulta pré-natal',
              fields: AGENDAR_CONSULTAS_FORM_LABELS,
            },
          },
        },
      },
    },
    atendimentoCompartilhadoLotacao: { label: 'Atendimento compartilhado' },
    desfechoAtendimento: {
      label: '',
      fields: {
        manterCidadaoLista: { label: 'Liberar cidadão' },
        atendimento: {
          label: '',
          fields: {
            agendado: { label: 'Próximos agendamentos do dia' },
            equipe: { label: 'Equipe' },
            responsavel: { label: 'Profissional' },
            tiposServico: { label: 'Tipo de serviço' },
          },
        },
      },
    },
    fichasNotificacaoCasoSuspeito: { label: 'Ficha de notificação de caso suspeito' },
    fornecimento: { label: 'Fornecimento' },
    procedimentosAdministrativos: { label: 'Procedimentos administrativos (SIGTAP)' },
    racionalidadeEmSaude: { label: 'Racionalidade em saúde (Exceto alopatia/convencional)' },
    cidadaoPresente: { label: 'Cidadão participou do atendimento' },
    tipoParticipacaoCidadao: { label: 'Forma de participação do cidadão' },
    tipoParticipacaoProfissionalConvidado: { label: 'Forma de participação do profissional convidado' },
  },
}

export const soapFormLabels: LabelMap<Required<SoapState>> = {
  acompanhamentoPreNatal: {
    label: 'Acompanhamento pré-natal',
    fields: {
      idadeGestacional: { label: 'Idade gestacional' },
    },
  },
  subjetivo: {
    label: 'Subjetivo',
    fields: {
      texto: { label: 'Campo aberto' },
      motivos: { label: 'Motivo da consulta (CIAP 2)' },
    },
  },
  objetivo: {
    label: 'Objetivo',
    fields: {
      texto: { label: 'Campo aberto' },
      dum: { label: 'DUM' },
      medicoes: {
        label: 'Antropometria, sinais vitais e glicemia capilar',
        fields: {
          altura: { label: 'Altura (cm)' },
          circunferenciaAbdominal: { label: 'Circunferência abdominal (cm)' },
          frequenciaCardiaca: { label: 'Frequência cardíaca (bpm)' },
          frequenciaRespiratoria: { label: 'Frequência respiratória (mpm)' },
          glicemia: { label: 'Glicemia capilar (mg/dL)' },
          perimetroCefalico: { label: 'Perímetro cefálico (cm)' },
          perimetroPanturrilha: { label: 'Perímetro da panturrilha (cm)' },
          peso: { label: 'Peso (kg)' },
          pressaoArterialDiastolica: { label: 'Pressão arterial (mmHg)' },
          pressaoArterialSistolica: { label: 'Pressão arterial (mmHg)' },
          saturacaoO2: { label: 'Saturação de O2 (%)' },
          temperatura: { label: 'Temperatura (°C)' },
          tipoGlicemia: { label: 'Momento da coleta' },
        },
      },
      resultadosExames: { label: 'Resultados de exames' },
      atendimentosEspecificos: {
        label: 'Atendimentos específicos',
        fields: {
          puericultura: {
            label: 'Puericultura',
            fields: {
              prenatalPartoNascimento: PRENATAL_PARTO_NASCIMENTO_FORM_LABELS,
              tipoAleitamento: { label: 'Tipo de aleitamento' },
              alteracoesFenotipicas: { label: 'Alterações fenotípicas' },
              fatoresRisco: { label: 'Fatores de risco' },
              marcosDesenvolvimento: { label: 'Marcos de desenvolvimento' },
              dataNascimentoReferencia: null,
              hasPuericultura: null,
              medicaoAnteriorPerimetroCefalico: null,
            },
          },
        },
      },
      necessidadesEspeciais: { label: 'Paciente com necessidades especiais' },
      vacinacaoEmDia: { label: 'Vacinação em dia?' },
      marcadoresConsumoAlimentar: { label: 'Marcadores de consumo alimentar' },
    },
  },
  avaliacao: {
    label: 'Avaliação',
    fields: {
      texto: { label: 'Campo aberto' },
      alergias: { label: 'Alergias' },
      problemasECondicoes: { label: 'Problemas e condições' },
      necessidadeProtese: { label: 'Necessidade de prótese' },
      tiposVigilancia: { label: 'Tipos de vigilância' },
      encerrarGestacao: {
        label: 'Encerrar gestação',
        fields: { dataDesfecho: { label: 'Data' }, tipoGravidez: { label: 'Tipo de gravidez' } },
      },
    },
  },
  plano: {
    label: 'Plano',
    fields: {
      texto: { label: 'Campo aberto' },
      atestado: { label: 'Atestados' },
      encaminhamentoExterno: { label: 'Encaminhamentos' },
      intervencoesProcedimentos: {
        label: 'Intervenções e/ou procedimentos clínicos realizados',
        fields: {
          ciaps: { label: 'CIAP 2' },
          procedimentos: { label: 'SIGTAP' },
        },
      },
      odontologia: {
        label: 'Evoluções odontológicas',
        fields: {
          limiteProcedimentos: { label: '' },
          odontograma: {
            label: 'Odontograma',
            fields: {
              cache: null,
              dentes: { label: 'Dentes' },
              arcadas: { label: 'Arcadas' },
              dentesSupranumerarios: { label: 'Dentes supranumerários' },
              outros: { label: 'Outros' },
              possuiAparelho: { label: 'Possui aparelho' },
              possuiContencao: { label: 'Possui contenção' },
              proteseTotalInferior: { label: 'Prótese total inferior' },
              proteseTotalSuperior: { label: 'Prótese total superior' },
            },
          },
          periodontia: {
            label: 'Periodontia',
            fields: {
              periogramaSimplificado: {
                label: 'Periograma simplificado',
                fields: {
                  avaliacaoSextante1: { label: 'S1' },
                  avaliacaoSextante2: { label: 'S2' },
                  avaliacaoSextante3: { label: 'S3' },
                  avaliacaoSextante4: { label: 'S4' },
                  avaliacaoSextante5: { label: 'S5' },
                  avaliacaoSextante6: { label: 'S6' },
                  observacao: { label: 'Observações' },
                  error: null,
                },
              },
              periogramaCompleto: {
                label: 'Periograma completo',
                fields: {
                  dados: { label: 'Periograma completo' },
                  observacao: { label: 'Observações' },
                },
              },
              evolucoes: { label: 'Registros de procedimentos' },
            },
          },
          tecidosMoles: { label: 'Tecidos moles e duros' },
        },
      },
      prescricaoMedicamento: {
        label: 'Prescrição de medicamentos',
        fields: {
          medicamentos: null,
          alreadyPrinted: null,
          replicateStorage: null,
          prescricaoDigital: null,
        },
      },
      orientacao: { label: 'Orientações ao cidadão' },
      solicitacoesExame: { label: 'Solicitação de exames' },
      startObservacao: { label: 'Cidadão em observação' },
      cuidadosCompartilhado: {
        label: 'Compartilhamento de cuidado',
        // TODO(@Supernova): Issue #18354
        fields: {
          cbo: { label: 'CBO' },
          unidadeSaude: { label: 'Unidade de saúde/Serviço de referência' },
          lotacao: { label: 'Profissional' },
          prioridade: { label: 'Classificação de prioridade' },
          discussao: { label: 'Discussão de caso clínico' },
          problemasECondicoes: {
            label: 'Problemas/condições',
            fields: {
              ciap: { label: 'CIAP 2' },
              cid10: { label: 'CID 10' },
            },
          },
          cidadaoAceitaAtendTic: { label: 'Cidadão aceita TIC' },
          tipo: null,
        },
      },
    },
  },
  finalizacao: FINALIZACAO_ATENDIMENTO_FORM_LABELS,
  antecedentes: {
    label: 'Ancentedentes',
    fields: {
      familiar: { label: '', fields: { ciaps: { label: 'Lista de problemas dos familiares' } } },
      pessoal: {
        label: '',
        fields: {
          texto: { label: 'Campo aberto' },
          cirurgiasInternacoes: { label: 'Antecedentes hospitalares' },
          informacoesObstetricas: {
            label: 'Informações obstétricas',
            fields: {
              abortos: { label: 'Abortos' },
              filhosVivos: { label: 'Vivem' },
              gestaPrevias: { label: 'Gestas prévias' },
              nascidosVivos: { label: 'Vivos' },
              natimortos: { label: 'Mortos' },
              obitoAntesPrimeiraSemana: { label: 'Mortos na 1ª semana' },
              obitoAposPrimeiraSemana: { label: 'Mortos depois da 1ª semana' },
              partoMenosDeUmAno: { label: 'Desfecho da última gestação aconteceu há menos de um ano' },
              partos: { label: 'Partos realizados' },
              partosCesareas: { label: 'Cesáreas' },
              partosDomiciliares: { label: 'Partos domiciliares' },
              partosVaginais: { label: 'Vaginais' },
              recemNascidoAbaixo: { label: 'Menos de 2500g' },
              recemNascidoAcima: { label: 'Mais de 4500g' },
              dataAtualizacao: null,
            },
          },
          puericultura: PRENATAL_PARTO_NASCIMENTO_FORM_LABELS,
        },
      },
    },
  },
  preNatal: {
    label: 'Pré natal',
    fields: {
      alturaUterina: { label: 'Altura uterina (cm)' },
      batimentoCardiacoFetal: { label: 'Batimento cardíaco fetal (bpm)' },
      edema: { label: 'Edema' },
      gravidezPlanejada: { label: 'Gravidez planejada' },
      movimentacaoFetal: { label: 'Movimentação fetal' },
      tipoGravidez: { label: 'Tipo de gravidez' },
    },
  },
  lembretes: { label: 'Lembretes' },
  problemasECondicoes: { label: 'Lista de problemas/condições' },
  vacinacao: {
    label: 'Vacinação',
    fields: {
      condicoesVacinacao: {
        label: 'Condições',
        fields: {
          gestante: { label: 'Gestante' },
          puerpera: { label: 'Puérpera' },
          viajante: { label: 'Viajante' },
          comunicanteHanseniase: { label: '' },
        },
      },
      finalizacaoVacinacao: FINALIZACAO_ATENDIMENTO_FORM_LABELS,
      imprimirAtendimento: null,
      registroVacinacao: null,
    },
  },
  medicamentosInterromper: { label: 'Medicamentos em uso/tratamento concluído' },
  medicoesAnteriores: { label: 'Medições' },
  imprimirAtendimento: null,
}
