import { DropdownItem, HFlow, Tooltip } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { DropdownButton } from 'components/dropdown'
import { HideOnScreenSize } from 'components/responsive'
import { ButtonLink } from 'components/route'
import { Action } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ReactComponent as IconDiscutirCaso } from 'images/cuidado-compartilhado/icon-discutir-caso.svg'
import { ReactComponent as IconVisualizarDiscussaoCaso } from 'images/cuidado-compartilhado/icon-visualizar-discussao-caso.svg'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import {
  CuidadoCompartilhadoItem,
  CuidadoCompartilhadoItemActions,
  ResponsavelCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getResponsabilidadeCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

interface ListaCuidadoCompartilhadoTableActionsProps {
  item: CuidadoCompartilhadoItem
  resolveDiscutirAction(actions: CuidadoCompartilhadoItemActions): Action
}

export const ListaCuidadoCompartilhadoTableActions = (props: ListaCuidadoCompartilhadoTableActionsProps) => {
  const { item, resolveDiscutirAction } = props

  const match = useRouteMatch()
  const history = useHistory()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()

  const responsavelCuidado: ResponsavelCuidadoCompartilhado = getResponsabilidadeCuidadoCompartilhado(
    acesso.id,
    item.lotacaoExecutanteAtual?.id,
    item.lotacaoSolicitante.id
  )

  const logEventVisualizar = () => {
    analytics.logEvent(
      responsavelCuidado === 'EXECUTANTE'
        ? 'visualizar_CC_executante'
        : responsavelCuidado === 'SOLICITANTE'
        ? 'visualizar_CC_solicitante'
        : 'visualizar_CC_outros'
    )
  }

  const logEventDiscutir = () => {
    analytics.logEvent(responsavelCuidado === 'EXECUTANTE' ? 'discutir_CC_executante' : 'discutir_CC_solicitante')
  }

  const visualizarDiscussaoLink = `${match.path.substring(0, match.path.lastIndexOf('/'))}/visualizar/${item.id}`
  const discutirCasoLink = `${match.url}/${item.id}`
  const discutirAction = resolveDiscutirAction(item.actions)

  const { hasAuthorization } = useSession()
  const hasVisualizar = hasAuthorization(Permissions.cuidadoCompartilhado.visualizarDiscussao)
  const hasDiscutir = hasAuthorization(Permissions.cuidadoCompartilhado.visualizarDiscussao.registrarDiscussaoDeCaso)

  return (
    item.actions &&
    hasVisualizar && (
      <>
        <HideOnScreenSize down='lg'>
          <HFlow hSpacing={0.5} justifyContent='flex-end'>
            <Tooltip text={item.actions.visualizarDiscussao.hint}>
              <ButtonLink
                size='small'
                skin='ghost'
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation()
                  logEventVisualizar()
                }}
                to={visualizarDiscussaoLink}
                disabled={!item.actions.visualizarDiscussao.enabled}
              >
                <IconVisualizarDiscussaoCaso />
              </ButtonLink>
            </Tooltip>
            <CheckPermission permission={Permissions.cuidadoCompartilhado.visualizarDiscussao.registrarDiscussaoDeCaso}>
              <Tooltip text={discutirAction.hint}>
                <ButtonLink
                  size='small'
                  skin='ghost'
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation()
                    logEventDiscutir()
                  }}
                  to={discutirCasoLink}
                  disabled={!discutirAction.enabled}
                >
                  <IconDiscutirCaso />
                </ButtonLink>
              </Tooltip>
            </CheckPermission>
          </HFlow>
        </HideOnScreenSize>
        <HideOnScreenSize up='lg'>
          {!hasDiscutir ? (
            <Tooltip text={item.actions.visualizarDiscussao.hint}>
              <ButtonLink
                size='small'
                skin='ghost'
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation()
                  logEventVisualizar()
                }}
                to={visualizarDiscussaoLink}
                disabled={!item.actions.visualizarDiscussao.enabled}
              >
                <IconVisualizarDiscussaoCaso />
              </ButtonLink>
            </Tooltip>
          ) : (
            <HFlow justifyContent='flex-end'>
              <DropdownButton icon='dots' stopPropagationOnClick>
                <Tooltip text={item.actions.visualizarDiscussao.hint}>
                  <DropdownItem
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation()
                      logEventVisualizar()
                      history.push(visualizarDiscussaoLink)
                    }}
                    disabled={!item.actions.visualizarDiscussao.enabled}
                  >
                    Visualizar discussão
                  </DropdownItem>
                </Tooltip>
                <CheckPermission
                  permission={Permissions.cuidadoCompartilhado.visualizarDiscussao.registrarDiscussaoDeCaso}
                >
                  <Tooltip text={discutirAction.hint}>
                    <DropdownItem
                      onKeyDown={(e) => e.stopPropagation()}
                      onClick={(e) => {
                        e.stopPropagation()
                        logEventDiscutir()
                        history.push(discutirCasoLink)
                      }}
                      disabled={!discutirAction.enabled}
                    >
                      Discutir caso
                    </DropdownItem>
                  </Tooltip>
                </CheckPermission>
              </DropdownButton>
            </HFlow>
          )}
        </HideOnScreenSize>
      </>
    )
  )
}
