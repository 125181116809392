import { useFlags } from 'config/useFlagsContext'
import { TipoModuloEnum } from 'graphql/types.generated'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { KeyMapping } from 'view/relatorio/relatoriosgerenciais/common/keyMapping-relatorioGerencial'

import {
  MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL,
  ModeloPersonalizadoRelatorioGerencialModal,
} from './ModeloPersonalizadorRelatorioGerencialModal'

interface ModeloPersonalizadoRelatorioGerencialRootViewProps {
  modulo: TipoModuloEnum
  keyMapping: Map<string, KeyMapping>
}

export function ModeloPersonalizadoRelatorioGerencialRootView(
  props: ModeloPersonalizadoRelatorioGerencialRootViewProps
) {
  const { modulo, keyMapping } = props
  const { url } = useRouteMatch()
  const { MODELOS_PERSONALIZADOS_ENABLED } = useFlags()
  return (
    <Switch>
      {MODELOS_PERSONALIZADOS_ENABLED && (
        <Route path={`${url}${MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL}/:id?`}>
          <ModeloPersonalizadoRelatorioGerencialModal modulo={modulo} keyMapping={keyMapping} />
        </Route>
      )}
    </Switch>
  )
}
