import { OpcaoSelecionadaRelatorioGerencialEnum, UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import { Record } from 'immutable'
import { InstantRange } from 'util/date/dateRange'

import { RelatorioGerencialFiltrosFormModel } from '../components/form/RelatorioGerencialFiltrosForm'

type RelatorioGerencialOpcoesPeriodoMapping = Record<
  OpcaoSelecionadaRelatorioGerencialEnum,
  RelatorioGerencialPeriodoOption
>

interface RelatorioGerencialPeriodoOption {
  label: string
  quantityToSubtract: number
}

export interface RelatorioGerencialFiltrosStorageModel extends Omit<RelatorioGerencialFiltrosFormModel, 'periodo'> {
  periodo?: InstantRange
}

export const relatorioGerencialDiaOpcoesRecord: Record<
  OpcaoSelecionadaRelatorioGerencialEnum,
  RelatorioGerencialPeriodoOption
> = {
  [OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA]: { label: 'Últimos 30 dias', quantityToSubtract: 30 },
  [OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA]: { label: 'Últimos 90 dias', quantityToSubtract: 90 },
  [OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA]: { label: 'Últimos 180 dias', quantityToSubtract: 180 },
  [OpcaoSelecionadaRelatorioGerencialEnum.OUTRO]: { label: 'Outro', quantityToSubtract: 0 },
}

export const relatorioGerencialSemanaOpcoesRecord: Record<
  OpcaoSelecionadaRelatorioGerencialEnum,
  RelatorioGerencialPeriodoOption
> = {
  [OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA]: { label: 'Última semana', quantityToSubtract: 1 },
  [OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA]: { label: 'Últimas 2 semanas', quantityToSubtract: 2 },
  [OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA]: { label: 'Últimas 4 semanas', quantityToSubtract: 4 },
  [OpcaoSelecionadaRelatorioGerencialEnum.OUTRO]: { label: 'Outro', quantityToSubtract: 0 },
}

export const relatorioGerencialMesOpcoesRecord: Record<
  OpcaoSelecionadaRelatorioGerencialEnum,
  RelatorioGerencialPeriodoOption
> = {
  [OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA]: { label: 'Último mês', quantityToSubtract: 1 },
  [OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA]: { label: 'Últimos 3 meses', quantityToSubtract: 3 },
  [OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA]: { label: 'Últimos 6 meses', quantityToSubtract: 6 },
  [OpcaoSelecionadaRelatorioGerencialEnum.OUTRO]: { label: 'Outro', quantityToSubtract: 0 },
}

export const relatorioGerencialPeriodoRecord: Record<
  UnidadePeriodoRelatorioGerencialEnum,
  RelatorioGerencialOpcoesPeriodoMapping
> = {
  [UnidadePeriodoRelatorioGerencialEnum.DIA]: relatorioGerencialDiaOpcoesRecord,
  [UnidadePeriodoRelatorioGerencialEnum.SEMANA]: relatorioGerencialSemanaOpcoesRecord,
  [UnidadePeriodoRelatorioGerencialEnum.MES]: relatorioGerencialMesOpcoesRecord,
}

export const RELATORIOS_GERENCIAIS_PERIOD_MAX_WEEKS = 52
export const RELATORIOS_GERENCIAIS_PERIOD_MAX_YEARS = 1
export const RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO = 'relatorios_bi_selecionou_relatório'

export const RELATORIO_GERENCIAL_ATENDIMENTOS_NAME = 'Relatório gerencial de atendimentos'
export const RELATORIO_GERENCIAL_VACINACAO_NAME = 'Relatório gerencial de vacinação'

export const RELATORIOS_GERENCIAIS_PATH = '/relatorios/gerenciais'
export const RELATORIO_GERENCIAL_ATENDIMENTOS_PATH = `${RELATORIOS_GERENCIAIS_PATH}/atendimentos`
export const RELATORIO_GERENCIAL_VACINACAO_PATH = `${RELATORIOS_GERENCIAIS_PATH}/vacinacao`

export const RELATORIO_GERENCIAL_ATENDIMENTO_FILTER_STORAGE_KEY = 'relatorio-gerencial/atendimento/filtro'
export const RELATORIO_GERENCIAL_VACINACAO_FILTER_STORAGE_KEY = 'relatorio-gerencial/vacinacao/filtro'

export const NO_ASYNC_LOADING_MESSAGE = 'Em processamento'
export const ASYNC_LOADING_MESSAGE =
  'Em processamento. Enquanto os dados estão em processamento, é possível navegar normalmente pelo sistema.'
