import { isCiap } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { AggregatorEnum } from 'components/pivot-table/components/aggregator/model-aggregator'
import { DroppableOrigin } from 'components/pivot-table/components/dragndrop/model-dragndrop'
import { PivotTableInitialValues } from 'components/pivot-table/PivotTable'
import {
  ModeloPersonalizadoRelatorioGerencial,
  ModeloPersonalizadoRelatorioGerencialInput,
  OpcaoSelecionadaRelatorioGerencialEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'
import { partition } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'

import { KeyMapping } from '../../common/keyMapping-relatorioGerencial'
import { calculatePeriodoRelatorioGerencial } from '../form/calculator-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../form/RelatorioGerencialFiltrosForm'
import { ModeloPersonalizadoRelatorioGerencialFormModel } from './form/model-modeloPersonalizadoRelatorioGerencialForm'

export function modeloPersonalizadoToPivotTableProps<T>(
  keyMapping: Map<string, KeyMapping>,
  modeloPersonalizado?: ModeloPersonalizadoRelatorioGerencial
): PivotTableInitialValues<T> {
  return modeloPersonalizado
    ? {
        fields: modeloPersonalizado.modelo.campos
          .filter((campo) => !isUndefinedOrNull(keyMapping.get(campo.key)?.visibility))
          .map((campo) => ({
            filters: campo.items,
            key: campo.key as keyof T,
            origin: DroppableOrigin[campo.posicao],
          })),
        aggregatorId: AggregatorEnum[modeloPersonalizado.modelo.aggregator],
      }
    : null
}

export function modeloPersonalizadoToTableWrapperProps(
  modeloPersonalizado?: ModeloPersonalizadoRelatorioGerencial
): RelatorioGerencialFiltrosFormModel {
  return {
    ciapsCids: [...(modeloPersonalizado?.modelo.ciaps ?? []), ...(modeloPersonalizado?.modelo.cids ?? [])],
    gruposCondicoesPrioritarios: modeloPersonalizado?.modelo.gruposCondicoes,
    opcaoSelecionada: modeloPersonalizado?.modelo.opcaoSelecionada ?? OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA,
    unidade: modeloPersonalizado?.modelo.unidadePeriodo ?? UnidadePeriodoRelatorioGerencialEnum.MES,
    modeloPersonalizado: {
      id: modeloPersonalizado?.id,
      nome: modeloPersonalizado?.nome,
    },
    periodo: calculatePeriodoRelatorioGerencial(
      modeloPersonalizado?.modelo.unidadePeriodo,
      modeloPersonalizado?.modelo.opcaoSelecionada,
      null
    ),
  }
}

export const relatorioGerencialFormModelToJsonModel = (
  values: ModeloPersonalizadoRelatorioGerencialFormModel
): ModeloPersonalizadoRelatorioGerencialInput => {
  const [ciaps, cids] = partition(values.ciapsCids, (ciapCid) => isCiap(ciapCid))
  return {
    aggregator: values.aggregator,
    ciapIds: ciaps?.map((ciapCid) => ciapCid.id),
    cidIds: cids?.map((ciapCid) => ciapCid.id),
    campos: values.campos.map((campo) => ({
      key: campo.key,
      posicao: campo.posicao,
      items: campo.items,
    })),
    gruposCondicoesIds: values.gruposCondicoes?.map((grupoCondicao) => grupoCondicao.id),
    opcaoSelecionada: values.opcaoSelecionadaModal,
    unidadePeriodo: values.unidadePeriodoModal,
  }
}
